<template>
  <div class="box">
    <p class="title">资源未找到</p>
    <p class="title-en">404 Not Found</p>
    <div class="label-box">
      <p class="mini-title">你所访问的资源未找到，可能有以下原因：</p>
      <p class="label">1：你访问的地址错误，请查正之后再访问。</p>
      <p class="label">2：你访问的资源被删除，请联系管理员。</p>
      <p class="label">3：由于您不是最新版本，导致页面未加载，请尝试清理手机缓存。</p>
      <p class="copyright" style="margin-top:0.9rem;">&copy;{{companyNameCh}}</p>
      <p class="copyright">{{companyNameEn}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "notFound",
  data() {
    return {
      companyNameCh: process.env.VUE_APP_COMPANY_NAME,
      companyNameEn: process.env.VUE_APP_COMPANY_ENGLISG_NAME,
    };
  },
};
</script>

<style lang="scss" scoped>
.box {
  height: 100%;
  background-color: #fff;
  .title {
    text-align: center;
    font-size: 0.5rem;
    font-weight: bold;
    margin: 2rem 0 0.2rem 0;
  }
  .title-en {
    text-align: center;
    font-size: 0.28rem;
    color: #666;
  }
  .label-box {
    width: 6.5rem;
    margin: 0.5rem auto;
    padding: 0.4rem 0;
    box-shadow: 0 0 8px #eee;
    border-radius: 6px;
    .mini-title {
      margin: 0.4rem 0.3rem;
    }
    .label {
      margin: 0.4rem;
    }
    .copyright {
      text-align: center;
      font-size: 12px;
      color: #999;
    }
  }
}
</style>